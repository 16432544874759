<template>
    <div> 

        <IconTopTable />
        <div class="content-table-results mt-2">
            <table class="table table-fixed table-stripes" id="table_Gba">
                <ColumnColGroup page="result" :column="data.column" :default_column="data.default_column" />
                <ColumnThead page="result" :column="data.column" :default_column="data.default_column" />
                <tbody id="tbody_Gba">
                    <template v-if="data.data.data && data.data.data.length > 0"> 
                        <template v-for="(item, key) in data.data.data" >             
                            <tr v-for="(sub_item, sub_key) in max_row(item)" :key="key+'_'+sub_key" :class="odd_even_border(key, sub_key)" > 
                                <td>
                                    <label class="table-check" v-if="sub_key===0">
                                        <input type="checkbox" v-model="select_export" :value="item.drug_id">
                                        <span class="checkbox-table"></span>
                                    </label>
                                </td>
                                <td>
                                    <router-link v-if="sub_key===0" :to="'/detail/'+item.drug_id+'/'+item.agency_model.toLowerCase()" target="_blank" rel="noopener">
                                        <div class="icon-fiche"></div>
                                    </router-link>
                                    <div v-if="sub_key===0 && isAdmin()">
                                        <a :href="modifyPage(item.agency_model.toLowerCase(), item.drug_id)" target="_blank" rel="noopener"> 
                                            <em class="icon-edit"></em>
                                        </a>
                                    </div>
                                </td>
                                <template v-for="(column) in data.column" :key="column.code" >

                                    <td v-if="column.Status && column.code == 'gba_001'" :code="column.code">
                                        <span v-if="sub_key===0" v-html="ema_id(item.links)"></span>                                    
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'gba_002'" :code="column.code">
                                        <template v-if="sub_key===0">{{item.drug_id}}</template>                              
                                    </td>
                                    <!-- <td v-else-if="column.Status && column.code == 'gba_003'" :code="column.code">
                                        <span v-if="sub_key===0" v-html="icon_link(item.drug_id, 'gba')"></span>
                                    </td> -->
                                    <td v-else-if="column.Status && column.code == 'gba_004'" :code="column.code">
                                        <template v-if="sub_key===0">{{check_empty(item.category)}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'gba_005'" :code="column.code">
                                        <template v-if="sub_key===0">{{check_empty(item.dci_new)}}</template>                                 
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'gba_006'" :code="column.code">
                                        <span v-if="sub_key===0" v-html="rembursement_status(item.reimbursement_decision, 'gba.rb_')"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'gba_043'" :code="column.code">
                                        <span v-if="sub_key===0"  v-html="check_empty(overview_regulatory_status(item.current_ma_status))"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'gba_007'" :code="column.code">
                                        <span v-if="sub_key===0" v-html="column_status(item)"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'gba_008'" :code="column.code">
                                        <template v-if="sub_key===0">{{check_empty(DDMMMYY(item.ma_date))}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'gba_009'" :code="column.code">
                                        <template v-if="sub_key===0">{{check_empty(DDMMMYY(item.gba.deadline_beginning))}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'gba_010'" :code="column.code">
                                        <template v-if="sub_key===0">{{check_empty(DDMMMYY(item.decision_date))}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'gba_011'" :code="column.code">
                                        <template v-if="sub_key===0">{{check_empty(item.name)}}</template> 
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'gba_012'" :code="column.code">                                
                                        <template v-if="sub_key===0">{{check_empty(item.dci)}}</template> 
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'gba_013'" :code="column.code">
                                        <template v-if="sub_key===0">{{check_empty(item.firm)}}</template>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'gba_014'" :code="column.code">
                                        <span v-if="sub_key===0" v-html="check_empty(array(item.atc))"></span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'gba_015'" :code="column.code">                                   
                                        <span v-if="sub_key===0" v-html="check_empty(array(item['indexation_'+$i18n.locale]))"></span>  
                                    </td>
                                    <!-- gba_populations start-->
                                    <td v-else-if="column.Status && column.code == 'gba_016'" :code="column.code">
                                        {{item.gba.gba_addition_benefit[sub_key] ? check_empty(item.gba.gba_addition_benefit[sub_key]['id_addition_benefits']) : '-'}}
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'gba_017'" :code="column.code" >                            
                                        <Readmore v-if="item.gba.gba_addition_benefit[sub_key]" :longText="item.gba.gba_addition_benefit[sub_key]['gba_addition_benefits_title']" />  
                                        <span v-else> - </span>                            
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'gba_018'" :code="column.code">                                
                                        <Readmore v-if="item.gba.gba_addition_benefit[sub_key]" 
                                            :longText="treatment_line(item.gba.gba_addition_benefit[sub_key]['gba_addition_benefits_treatment_line']) +
                                            (item.gba.gba_addition_benefit[sub_key]['gba_addition_benefits_treatment_specificity'] ? '<p>'+item.gba.gba_addition_benefit[sub_key]['gba_addition_benefits_treatment_specificity']+'</p>' : '')  +
                                            (item.gba.gba_addition_benefit[sub_key]['gba_addition_benefits_treatment_administration'] ? '<p>'+item.gba.gba_addition_benefit[sub_key]['gba_addition_benefits_treatment_administration']+'</p>' : '')" 
                                            textAlign="center" />
                                        <span v-else> - </span>
                                    </td>
                                    <td v-else-if="column.Status && column.code == 'gba_019'" :code="column.code">
                                        <span v-if="item.gba.gba_addition_benefit[sub_key]" :class="color_added_benefit(item.gba.gba_addition_benefit[sub_key]['gba_addition_benefits_gba_added_benefit_id'])"> 
                                            {{check_empty_with_locale(item.gba.gba_addition_benefit[sub_key]['gba_addition_benefits_gba_added_benefit_id'],'gba_added_benefit_id_')}}
                                        </span>
                                        <span v-else> - </span>
                                    </td>
                                    <!-- gba_populations end-->
                                    <td v-else-if="column.Status && column.code == 'gba_020'" :code="column.code">                             
                                        <span v-if="sub_key===0" :class="color_type_limitation(item.prioritis_hta_index)">{{check_empty_with_locale(item.prioritis_hta_index,'conclusion.')}}</span>
                                    </td>       

                                    <template v-else-if="column.Status && column.code == 'gba_021'" >
                                        <td  :key="column.code+'_022'" code="gba_022" >
                                            <template v-if="sub_key===0" > 
                                                <Readmore v-if="$i18n.locale === 'de'" :longText="item.target_population_de" />
                                                <Readmore v-else :longText="item.target_population"/>                               
                                            </template>
                                        </td>                            
                                        <td  :key="column.code+'_023'" code="gba_023" >                              
                                        <template v-if="sub_key===0" > 
                                                <Readmore v-if="$i18n.locale === 'de'" :longText="item.prescription_and_delivery_conditions_de" />
                                                <Readmore v-else :longText="item.prescription_and_delivery_conditions"/>                      
                                            </template>
                                        </td>                            
                                        <td  :key="column.code+'_024'" code="gba_024" >
                                            <template v-if="sub_key===0" > 
                                                <Readmore v-if="$i18n.locale === 'de'" :longText="item.access_scheme_de" />
                                                <Readmore v-else :longText="item.access_scheme"/>                       
                                            </template>

                                        </td>
                                        <td  :key="column.code+'_025'" code="gba_025" >
                                            <template v-if="sub_key===0" > 
                                                <Readmore v-if="$i18n.locale === 'de'" :longText="item.outcome_level_de" />
                                                <Readmore v-else :longText="item.outcome_level"/>   
                                            </template>
                                        </td>
                                    </template>                            
                                    <td v-else-if="column.Status && column.code == 'gba_026'" :code="column.code">                                
                                        <template v-if="sub_key===0" > 
                                            <Readmore v-if="$i18n.locale === 'de'" :longText="item.rationale_and_comments_html_de"/>                           
                                            <Readmore v-else :longText="item.rationale_and_comments_html_en"/>                           
                                        </template>  
                                    </td>      
                                    <td v-else-if="column.Status && column.code == 'gba_027'" :code="column.code">
                                        <template v-if="sub_key===0" > 
                                            <Readmore v-if="$i18n.locale === 'de'" :longText="item.indication_de"/>             
                                            <Readmore v-else :longText="item.indication_en"/>             
                                        </template>  
                                    </td>   
                                    <td v-else-if="column.Status && column.code == 'gba_028'" :code="column.code">                                
                                    <template v-if="sub_key===0" > 
                                            <span v-if="$i18n.locale !== 'fr'" v-html="check_empty(array(item.classification_en))"  ></span>
                                            <span v-else v-html="check_empty(array(item.classification_fr))"></span>
                                        </template>  
                                    </td>   
                                    <td v-else-if="column.Status && column.code == 'gba_029'" :code="column.code">                                
                                        <template v-if="sub_key===0">{{check_empty_with_locale(item.mk_orphelin)}}</template>
                                    </td>   
                                    <td v-else-if="column.Status && column.code == 'gba_030'" :code="column.code">                            
                                        <span v-if="sub_key===0" v-html="nct_number(item.nct_numbers)" @click="drug_id = item.drug_id"></span>
                                    </td>   
                                    <td v-else-if="column.Status && column.code == 'gba_031'" :code="column.code">
                                        <span v-if="sub_key===0" v-html="check_empty(treatment_line(item.treatment_line))"></span>
                                    </td>   
                                    <td v-else-if="column.Status && column.code == 'gba_032'" :code="column.code">
                                        <template v-if="sub_key===0">{{check_empty(item.specificity) }}</template>
                                    </td>   
                                    <td v-else-if="column.Status && column.code == 'gba_033'" :code="column.code">
                                        <template v-if="sub_key===0">{{check_empty(item.administration) }}</template>
                                    </td>  

                                    <td v-else-if="column.Status && column.code == 'gba_044'" :code="column.code">
                                        <template v-if="sub_key===0">{{check_empty_with_locale(item.gba.indirect_comparison_data) }}</template>
                                    </td>  
                                    <td v-else-if="column.Status && column.code == 'gba_045'" :code="column.code">
                                        <template v-if="sub_key===0">
                                            <Readmore :longText="item.gba.population_ic"/>             
                                        </template>
                                    </td> 
                                     <td v-else-if="column.Status && column.code == 'gba_046'" :code="column.code">
                                        <template v-if="sub_key===0">{{check_empty_with_locale(item.gba.acepted_ic) }}</template>
                                    </td>   
                                    <td v-else-if="column.Status && column.code == 'gba_047'" :code="column.code">
                                        <template v-if="sub_key===0">{{check_empty_with_locale(item.gba.quality_of_life_data) }}</template>
                                    </td>  
                                    <td v-else-if="column.Status && column.code == 'gba_048'" :code="column.code">
                                        <template v-if="sub_key===0">
                                            <Readmore :longText="item.gba.type_of_qol_data_presented"/>             
                                        </template>
                                    </td> 
                                    <td v-else-if="column.Status && column.code == 'gba_050'" :code="column.code">
                                        <template v-if="sub_key===0">{{check_empty_with_locale(item.gba.accepted_qol) }}</template>
                                    </td>

                                    <template v-else-if="column.Status && column.code == 'gba_034'">                         
                                        <td  :key="column.code+'_035'" code="gba_035">
                                            <template v-if="sub_key===0">{{check_empty(DDMMMYY(item.hta_process_date)) }}</template>
                                        </td>   
                                        <td  :key="column.code+'_036'" code="gba_036">                            
                                            <span v-if="sub_key===0" v-html="hta_process_status(item, 'hta2_')"></span>
                                        </td>   
                                        <td  :key="column.code+'_037'" code="gba_037" >                                
                                            <template v-if="sub_key===0">{{check_empty(DDMMMYY(item.rembursement_process_date))}}</template>
                                        </td>     
                                        <td  :key="column.code+'_038'" code="gba_038" >                               
                                            <span v-if="sub_key===0" v-html="rembursement_status(item.reimbursement_decision, 'gba.rb_')"></span>
                                        </td>     
                                    </template>
                                    <template v-else-if="column.Status && column.code == 'gba_039'">                                   
                                        <td  :key="column.code+'_040'" code="gba_040" >
                                            <span data-bs-toggle="modal" data-bs-target="#modal-hta-status" @click="hta_status_modal(item)" v-if="sub_key===0" v-html="hta_process_status_2(item)"></span>
                                        </td>     
                                        <td  :key="column.code+'_041'" code="gba_041" >
                                            <span data-bs-toggle="modal" data-bs-target="#modal-hta-status" @click="hta_status_modal(item)" v-if="sub_key===0" v-html="rembursement_status_2(item)"></span>                      
                                        </td>     
                                        <td  :key="column.code+'_042'" code="gba_042">                                    
                                            <template v-if="sub_key===0">
                                                {{datediff(item.ma_date, item.rembursement_process_date)}}
                                            </template>                     
                                        </td>     
                                    </template>
                                </template>
                            </tr>
                        </template>
                    </template>

                    <template v-else><tr><td colspan="99">{{$t('No Data')}}</td></tr></template>
                    
                </tbody>
            </table>
        </div> 
        <ModalHTAstatus :item="item_hta_status" />   
        <ModalEssaisClinique :id="drug_id" agency="Gba" />  
    </div>
</template>

<script>

import IconTopTable from '@/components/result/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue';
import Readmore from '@/components/elements/readmore.vue';
import ModalHTAstatus from '@/components/result/modal_hta_status.vue'
import { isAdmin, hta_process_status, rembursement_status, hta_process_status_2, 
        rembursement_status_2,column_status, overview_regulatory_status, modifyPage, 
        ema_id, icon_link, DDMMMYY, check_empty, nct_number, treatment_line, datediff, 
        array, color_type_limitation, odd_even_border, check_empty_with_locale } from '@/utils'
import ModalEssaisClinique from '@/components/elements/modal_essais_clinique.vue'
export default {
    name: "Gba",
    components: {
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        Readmore,
        ModalHTAstatus,
        ModalEssaisClinique
    },
    data : function() {
        return { 
            item_hta_status : {},
            drug_id : 0 //for essais clinique popup
        }
    },
     computed: {                
        data(){
            return this.$store.getters['result/data'];
        },
        select_export: {
            set(val){ this.$store.dispatch("result/select_export", val) },
            get(){ 
                if(this.$store.getters['result/select_export_checkAll']){
                    return true
                }else{
                    return this.$store.getters['result/select_export'] ? this.$store.getters['result/select_export'].listID : null 
                }
            }
        }
    },
    methods: {
        overview_regulatory_status,
        rembursement_status_2,
        hta_process_status_2,
        column_status,
        rembursement_status, 
        hta_process_status,  
        isAdmin,
        ema_id,
        icon_link,
        DDMMMYY,
        check_empty,
        nct_number,
        treatment_line,
        datediff,
        array,
        color_type_limitation,
        odd_even_border,
        check_empty_with_locale,
        hta_status_modal(item){
            this.item_hta_status = item
        },
        color_added_benefit(param){
            let txt = ''
            let status = {
                '2':'green',
                '3':'green',
                '4':'green',
                '5':'green',
                '6':'orange',
                '7':'red'
                    }
             if ( status[param] ) {
                 txt = status[param]
             }
             return txt
        },
        max_row(param){
            const temp = []
            temp.push(1)              
           
            if(param.gba.gba_addition_benefit){
                temp.push(param.gba.gba_addition_benefit.length)
            }
            
            return Math.max(...temp)
        },
        getResults(page = 1) {
            console.log(page)
        },        
        modifyPage
    },
   
}
</script>

<style scoped>
/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 70vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}
</style>